import React from "react";
import Footer from "../../components/footer/Footer";
import Header from "../../components/header/Header";
import "./style.css";

const Stall = () => {
  return (
    <>
      <Header />
      <div>
        <div className="about-head p-3 p-md-5">
          <div className="container">
            <center>
              <h2>Book A Stall</h2>
            </center>
          </div>
        </div>
        <div className="container about pt-2 pt-md-5 pb-2 pb-md-5">
          <div className="stall-categories">
            <p>
              Exhibitors can choose a stall space as per their requirement under
              different categories as tabulated below:
            </p>
            <div className="table">
              <table>
                <thead>
                  <tr>
                    <th>S.N</th>
                    {/* <th>Stall Categories</th> */}
                    <th>Approx. Size</th>
                    <th>Cost/stall (NPR.)</th>
                    {/* <th>Remarks</th> */}
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td>1.</td>
                    {/* <td>Enterprise supported by Donor agencies.</td> */}
                    <td>5*15ft</td>
                    <td>Rs. 4,00,000.00/-</td>
                    {/* <td>Information materials such as Booklets, Brochure,</td> */}
                  </tr>
                  <tr>
                    <td>2.</td>
                    {/* <td>Youth Innovations/Startups</td> */}
                    <td>5*10ft</td>
                    <td>Rs. 3,00,000.00/-</td>
                    {/* <td>Tech, new products.</td> */}
                  </tr>
                  <tr>
                    <td>3.</td>
                    {/* <td>Nepali Products</td> */}
                    <td>5*5ft</td>
                    <td>Rs. 2,00,000.00/-</td>
                    {/* <td>Handicrafts, organic products, Nepali Clothing,</td> */}
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
          <div className="container-contact">
            <div className="contact-content">
              <div className=" stall_cat">
                <p>
                  <h3>Stall Categories</h3>
                  <li>Business</li>
                  <li>Nepali Products</li>
                  <li>Startups</li>
                  <li>Social Entrepreneurs</li>
                  <li>NGO/INGO</li>
                  <li>Product Display</li>
                  <li>Service Display</li>
                  <li>Other</li>
                </p>
              </div>
              <div className="left-side">
                <h3>More Info</h3>
                <div className="address details">
                  <i className="fas fa-map-marker-alt" />
                  <div className="topic">Event Venue</div>
                  <div className="text-one">Kathmandu Nepal</div>
                </div>
                <div className="phone details">
                  <i className="fas fa-phone-alt" />
                  <div className="topic">Contact</div>
                  <div className="text-one">9841049104</div>
                </div>
                <div className="email details">
                  <i className="fas fa-envelope" />
                  <div className="topic">Email</div>
                  <div className="text-one">nepalbusinesssummit@gmail.com</div>
                </div>
              </div>
              <div className="right-side">
                <a
                  href="https://forms.gle/oYdJyzPkLRDvagzM6"
                  target="__blank"
                  className="form_btn"
                >
                  Book Now
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </>
  );
};

export default Stall;
