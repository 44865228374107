import React from "react";
import Footer from "../../components/footer/Footer";
import Header from "../../components/header/Header";
import { FaHandPointRight } from "react-icons/fa";
import "../article.css";

const Faq = () => {
  return (
    <div>
      <Header />
      <div className="about-head p-3 p-md-5">
        <div className="container ">
          <h2 className="text-center">FAQ's</h2>
        </div>
      </div>
      <div className="container mt-4 faqs">
        <div className="faq">
          <div className="faq-item">
            <h2>1. What is the Nepal Business Summit?</h2>
            <p>
              <FaHandPointRight className="hand_icon" /> &nbsp; The Nepal
              Business Summit (NBS), themed "Economy for a Sustainable and
              Resilient Future," is a flagship event organized by the Government
              of Nepal, Ministry of Industry, Commerce and Suppliers, National
              Planning Commission, and Nepal Business Institute (NBI). Scheduled
              for 2025, the summit aims to accelerate Nepal’s economic growth by
              fostering collaboration among government officials, industry
              leaders, and entrepreneurs. This one-day event will explore
              Nepal’s economic potential and drive innovation for sustainable
              development.
            </p>
          </div>
          <div className="faq-item">
            <h2>2. Who organizes the Nepal Business Summit?</h2>
            <p>
              {" "}
              <FaHandPointRight className="hand_icon" /> &nbsp; Organized by key
              stakeholders, including:
            </p>
            <ul>
              <li>Nepal Business Institute (NBI)</li>
              <li>
                Government of Nepal's Ministry of Industry, Commerce, and
                Supplies
              </li>
              <li>Nepal Chamber of Commerce (NCC)</li>
            </ul>
          </div>
          <div className="faq-item">
            <h2>
              3. How many people will participate and through what medium?
            </h2>
            <p>
              <FaHandPointRight className="hand_icon" /> &nbsp; Around 500
              attendees will participate physically, and 2000 will join
              virtually.
            </p>
            <p>Physical participation mediums:</p>
            <ol>
              <li>Invitations</li>
              <li>Registration</li>
              <li>Partner organizations</li>
            </ol>
          </div>
          <div className="faq-item">
            <h2>4. When and where is the next Nepal Business Summit?</h2>
            <p>
              <FaHandPointRight className="hand_icon" /> &nbsp; <b>Date:</b>{" "}
              27th April 2025
              <br />
              <b>Venue:</b>Kathmandu Nepal
              <br />
              Please visit the official website{" "}
              <a href="https://nepalbusinesssummit.com" target="__blank">
                https://nepalbusinesssummit.com
              </a>{" "}
              for the latest updates.
            </p>
          </div>
          <div className="faq-item">
            <h2>5. What are the objectives of the Nepal Business Summit?</h2>
            <p>
              {" "}
              <FaHandPointRight className="hand_icon" /> &nbsp; The summit
              focuses on:
            </p>
            <ul>
              <li>Promoting investment opportunities in Nepal</li>
              <li>
                Strengthening public-private partnerships to address economic
                challenges
              </li>
              <li>
                Highlighting entrepreneurship and innovation as key drivers for
                development
              </li>
              <li>
                Encouraging policy dialogue to create a favorable business
                environment
              </li>
              <li>
                Facilitating networking among local and international
                stakeholders
              </li>
            </ul>
          </div>

          <div className="faq-item">
            <h2>6. Who can attend the Nepal Business Summit?</h2>
            <p>
              {" "}
              <FaHandPointRight className="hand_icon" /> &nbsp; The summit is
              open to:
            </p>
            <ul>
              <li>Entrepreneurs and startups</li>
              <li>Investors and venture capitalists</li>
              <li>Corporate executives and business leaders</li>
              <li>Government officials and policymakers</li>
              <li>Academics and industry experts</li>
              <li>Media and press representatives</li>
              <li>Anyone interested in Nepal's business-related activities</li>
            </ul>
          </div>

          <div className="faq-item">
            <h2>7. What sessions and activities can participants expect?</h2>
            <p>
              {" "}
              <FaHandPointRight className="hand_icon" /> &nbsp; The summit
              features:
            </p>
            <ul>
              <li>Keynote addresses by industry leaders and policymakers</li>
              <li>
                Panel discussions on relevant economic and business topics
              </li>
              <li>
                Workshops and breakout sessions for skills development and
                knowledge sharing
              </li>
              <li>
                Networking opportunities with potential partners and investors
              </li>
              <li>
                Exhibition booths for startups and businesses to showcase their
                offerings
              </li>
            </ul>
          </div>

          <div className="faq-item">
            <h2>8. How do I register for the Nepal Business Summit?</h2>
            <p>
              {" "}
              <FaHandPointRight className="hand_icon" /> &nbsp; To register:
            </p>
            <ol>
              <li>
                Visit the website:{" "}
                <a href="https://nepalbusinesssummit.com" target="__blank">
                  https://nepalbusinesssummit.com
                </a>
              </li>
              <li>Fill up the online registration form</li>
              <li>Complete the online payment</li>
              <li>
                After successful registration, an event detail confirmation
                email will be sent
              </li>
            </ol>
          </div>

          <div className="faq-item">
            <h2>9. What is the main attraction of this Summit?</h2>
            <p>
              <FaHandPointRight className="hand_icon" /> &nbsp; The main
              attractions of the Nepal Business Summit 2025 include:
            </p>
            <ul>
              <li>Keynote Speeches</li>
              <li>Panel Discussions</li>
              <li>Networking Opportunities</li>
              <li>Startup & SME Exhibition</li>
              <li>Workshops & Breakout Sessions</li>
              <li>Nepali Foods</li>
              <li>Exhibition</li>
              <li>Live Music</li>
            </ul>
          </div>

          <div className="faq-item">
            <h2>10. How much does it cost to attend the summit?</h2>
            <p>
              <FaHandPointRight className="hand_icon" /> &nbsp; The registration
              fee is different according to your role, such as a general
              participant, student, or exhibitor. Fee-related details will be
              provided on the website.
            </p>
          </div>

          <div className="faq-item">
            <h2>11. What is included in the registration cost?</h2>
            <p>
              {" "}
              <FaHandPointRight className="hand_icon" /> &nbsp; The registration
              cost includes:
            </p>
            <ul>
              <li>
                Access to all sessions: Keynote speeches, panel discussions, and
                workshops
              </li>
              <li>
                Event materials: Souvenirs, brochures, and session handouts
              </li>
              <li>
                Networking opportunities: Connect with industry leaders,
                entrepreneurs, and policymakers
              </li>
              <li>
                Refreshments: Lunch, snacks, tea/coffee, and cocktail dinner
              </li>
              <li>
                Exhibition access: Entry to stalls and booths showcasing
                innovative products and services
              </li>
              <li>
                Post-event resources: Access to presentation materials and
                recorded sessions (for registered attendees)
              </li>
            </ul>
          </div>

          <div className="faq-item">
            <h2>
              12. Are there any opportunities available for sponsorships and
              partnerships?
            </h2>
            <p>
              <FaHandPointRight className="hand_icon" /> &nbsp; Yes, the Nepal
              Business Summit offers a platform to sponsors and partners.
              Benefits include:
            </p>
            <ul>
              <li>Visibility at the event</li>
              <li>
                Networking opportunities with business leaders and government
                officials
              </li>
              <li>Recognition through event promotions and media coverage</li>
            </ul>
            <p>
              For sponsorship inquiries, visit the website or contact the
              organizing team.
            </p>
          </div>

          <div className="faq-item">
            <h2>13. How do I book a stall or exhibition booth?</h2>
            <p>
              {" "}
              <FaHandPointRight className="hand_icon" /> &nbsp; To book a stall:
            </p>
            <ol>
              <li>Log on to the website and click 'Stall Book'</li>
              <li>Fill out the registration form</li>
              <li>Submit your business details along with your preferences</li>
              <li>Make the payment to complete the process</li>
            </ol>
            <p>
              For any queries, contact the organizing team through the website.
            </p>
          </div>

          <div className="faq-item">
            <h2>14. How do I keep updated on information about the summit?</h2>
            <p>
              {" "}
              <FaHandPointRight className="hand_icon" /> &nbsp; Stay updated by:
            </p>
            <ul>
              <li>Frequently checking the official website</li>
              <li>Subscribing to their social media accounts</li>
              <li>Subscribing to the newsletter for updates</li>
            </ul>
          </div>

          <div className="faq-item">
            <h2>15. Is it open for international participants?</h2>
            <p>
              <FaHandPointRight className="hand_icon" /> &nbsp; Yes, the summit
              is open to all international participants, offering both virtual
              and in-person options. International participants can also seek
              visa assistance if required.
            </p>
          </div>

          <div className="faq-item">
            <h2>16. Is there a refund policy for cancellations?</h2>
            <p>
              <FaHandPointRight className="hand_icon" /> &nbsp; The refund
              policy for cancellations will be published on the website along
              with the terms and conditions of registration.
            </p>
          </div>

          <div className="faq-item">
            <h2>
              17. Are there opportunities at the summit for startups and small
              businesses?
            </h2>
            <p>
              <FaHandPointRight className="hand_icon" /> &nbsp; Yes, the summit
              is a venue for showcasing your products and services to startups,
              SMEs, and potential investors. Special sessions and exhibitions
              are often organized to support and promote emerging businesses.
            </p>
          </div>

          <div className="faq-item">
            <h2>18. Whom do I contact for more information?</h2>
            <p>
              {" "}
              <FaHandPointRight className="hand_icon" /> &nbsp; For further
              questions, you can:
            </p>
            <ul>
              <li>
                Email:
                <a href="mailto:nepalbusinesssummit@gmail.com">
                  nepalbusinesssummit@gmail.com
                </a>
              </li>
              <li>Call: 9841049104</li>
            </ul>
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
};

export default Faq;
